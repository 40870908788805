import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { Accordion, Button } from '/src/components/common'

const accordionItems = [
  {
    answer: '<p>Metalcorp operates across Australia with branches and stockists in New South Wales, Queensland, Victoria, South Australia and Western Australia. To find a location nearest to you please use our branch locator</p>',
    question: 'Where can I find the nearest location?'
  },
  {
    answer: '<p>Our highly trained Australia based customer service team can help you with your steel requirements including delivery to most metropolitan and rural parts of Australia. Please note that freight costs vary based on the products that you order and your location. Please contact our Customer Service team on 1300 306 204 for assistance.</p>',
    question: 'Do you deliver?'
  },
  {
    answer: '<p>Metalcorp is a proud supporter of the Australian made campaign. The majority of Metalcorp products are manufactured with Australian made Orrcon Steel. Orrcon Steel has two manufacturing plants, one in Brisbane specializing in structural steel and one in Adelaide which specializes in precision steel. Most steel coils that Orrcon Steel uses are produced here in Australia resulting in a better-quality product than imported coil. Orrcon Steel does import a range of larger pipe and other large structural products.</p>',
    question: 'Where are your products manufactured?'
  },
  {
    answer: '<p>Metalcorp can provide a range of steel processing services, including length based cutting. We specialise in CNC Router Cutting, drilling, guillotining, slitting, roll and cut grooving, bending-to-shape and sawing. We also work in partnership with several laser-cutting services to tackle complex metal folding and edge rounding for our customers. Call our customer service team on 1300 306 204 to find out about our partnerships with 3D laser cutting facilities offsite.</p>',
    question: 'Do you cut to length?'
  },
  {
    answer: '<p>All Metalcorp products come with SDS to ensure our customers are using the correct safety procedures when storing, handling, transporting and using our products. We provide a list of potential health hazards and impacts on the environment. We also list a range of potentially harmful chemicals that are used in our coated steel products and what to do if accidents and emergencies occur.</p>',
    question: 'What Safety Data Sheets (SDS) can you provide?'
  },
  {
    answer: '<p>Metalcorp participates in a list of Field Days in QLD, NSW and Victoria. At the start of 2018 Metalcorp teamed up with the National Centre for Farmer Health (NCFH) to make a difference to farmers lives and give back to the rural community. Please call your local Branch to find out about upcoming Field Days in your area. Our partnership with NCFH will ensure there will be health professionals providing farmers will access to free health and lifestyle assessments and programs focused on occupational health, wellbeing and safety for agricultural workers.</p>',
    question: 'Do you participate in Field Days?'
  },
  {
    answer: '<p>Under Australian Consumer Law you have the right to ask for a repair, replacement or refund If any of our products or services fail to meet the guarantees we have made. Please visit your nearest Branch to discuss our refund policy.</p>',
    question: 'Do you offer refunds and returns?'
  },
  {
    answer: '<p>Metalcorp is an Australian steel distribution business that is into everything in steel.</p>',
    question: 'Who is Metalcorp?'
  },
  {
    answer: '<p>Yes. This site enables encrypted and secure online transactions. Simply add your products to the cart and choose delivery or pickup. We accept Mastercard, Visa and PayPal. </p>',
    question: 'Can I pay online?'
  }
]

const FAQPage = () => {
  return (
    <Layout hero={<HeroImage title="FAQS" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col xs={10}>
          <p>See below a list of frequently asked questions.</p>
          <Accordion items={accordionItems} />
        </Col>
      </Row>
    </Layout>
  )
}

export default FAQPage

export const Head = () => {
  return (
    <>
      <title>FAQS | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}
